@import "functions";
@import "fonts";

$primary: #143557;
$secondary: #0094db;
$light: #e4e9ef;
$dark: #212529;
$white: #fff;
$cg-5:50px;
$cg-4:40px;
$enable-dark-mode:false;
$font-family-base:'Rubik';
$font-weight-normal:300;
$font-weight-light:300;
$font-weight-bold:600;
$font-weight-medium:500;
$line-height-base:1.3;
$font-size-base:1.25rem;
$font-size-sm:1rem;
$h1-font-size: $font-size-base * 3.5;
$h2-font-size: $font-size-base * 1.75;
$success:#80bb8c;
$warning:#dcd63b;
$danger:#d24e46;
$info:#82cff5;


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 990px,
  xl: 1225px,
  xxl: 1745px
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px,
  xxl: 1850px
);

.scroll-smooth {
  scroll-margin-top: 110px;
  scroll-behavior: smooth;
}

@import "bootstrap";

.btn-secondary{
  color:$white;
  &:hover {
    color: $white;  // Adjust the hover color as needed
  }

  &:active {
    color: $white;  // Adjust the active color as needed
  }


}

.touch-padding{
     min-height: 24px;
     min-width: 24px;
     display: inline-block;
  }
.swiper-pagination-bullet{
     min-height: 16px;
     min-width: 16px;
     display: inline-block;
     padding: 4px
 }


* {touch-action: manipulation;}
b, strong {font-weight:$font-weight-bold;}
.fw-light {
    strong {font-weight:$font-weight-medium;}
}
h1, h2, h3, h4 {color:$primary;line-height:1;font-weight:$font-weight-light;
    strong {font-weight:$font-weight-bold;}
    .text-light & {color:$white;}
}
img {height:auto;max-width:100%;}
.header {position:fixed !important;top:0;left:0;width:100%;background:$white;z-index:5;font-size:$font-size-sm;transition:ease all .5s;
    .nav-up & {top: -160px !important;}
    .btn {font-size:$font-size-sm}
    &__logo {max-height:70px;margin:30px 0 20px;}
    a.nav-link:hover {color:$secondary;}
    &__topnav {top: -10px;right:0px;padding:0;width: auto;}
    &__topnav-ul {margin:0;padding:0;list-style:none;}
    &__topnav-li {margin:0;padding:0 10px;display:inline-block;}
    &__schatten {position:absolute;top:115px;left:0;width:100%;}
}
header .language {margin-left:12px;
    &__image {width:36px;}
    &__link {padding:5px;font-size:15px;}
    &__dropdown {min-width:142px;padding:0;top:40px !important;right:-20px;left:auto !important;}
}
.hero {position:relative;
    &__teaser {position:absolute;top:425px;left:50%;transform:translate(-50%, -50%);z-index:1;}


    @include media-breakpoint-up(lg) {
        .hero__teaser { top:300px }
    }
    @include media-breakpoint-up(xl) {
        .hero__teaser { top:350px }
    }
    @include media-breakpoint-up(xxl) {
        .hero__teaser { top:400px }
    }
    &__text {max-width:700px;}
    h1 {font-weight:$font-weight-light;margin-bottom:50px;
        strong {font-weight:$font-weight-bold;}
    }
    &__list {margin:0;padding:0;list-style:none;}
    &__list-item {padding:5px 10px 5px 27px;display:block;width:470px;max-width:90%;position:relative;background:$light;border-radius:8px;margin:10px 0 0;
        i {position:absolute;left:5px;top:6px;}}
    &__list-item {}
    &__bg::after {content:"";width:60vw;height:100%;position:absolute;left:0;top:0;background:linear-gradient(to right, $black, transparent);opacity:.8;z-index:0;}
    &__bg-dark::after {content:"";width:100vw;height:100%;position:absolute;left:0;top:0;background:$black;opacity:.4;z-index:0;}
    &__bg-light::after {content:"";width:100vw;height:100%;position:absolute;left:0;top:0;background:$white;opacity:.4;z-index:0;}
}
.auswahl {position:relative;z-index:1;
    &__suchformular {}
    .btn {font-size:$font-size-sm;}

    @media (max-width:1900px) {
        &__spezial {padding-top: 0px !important; }
    }

    @include media-breakpoint-down(xxl){
        &__spezial {padding-top: 25px !important; }
    }

    @include media-breakpoint-down(xl){
        &__spezial {padding-top: 30px !important; }
    }

    @media (max-width:1200px) {
        &__spezial {padding-top: 70px !important; }
    }

    @media (max-width:1100px) {
        &__spezial {padding-top: 90px !important; }
    }
    @media (max-width:1050px) {
        &__spezial {padding-top: 105px !important; }
    }

    @media (max-width:1025px) {
        &__spezial {padding-top: 100px !important; }
    }


    @include media-breakpoint-down(lg){
        &__spezial {padding-top: 420px !important; }
    }
    @media (max-width:900px) {
        &__spezial {padding-top: 460px !important; }
    }
    @include media-breakpoint-down(md){
        &__spezial {padding-top: 0px !important; }
    }

    @include media-breakpoint-down(sm){
        &__spezial {padding-top: 0px !important; }
    }

    /*

    @media (max-width:650px) {
        &__spezial {padding-top: 50px !important; }
    }
    @media (max-width:550px) {
        &__spezial {padding-top: 250px !important; }
    }*/



}





.boxen {
    &__row {}
    h2 {font-weight:$font-weight-light;margin:35px 0 15px;
        strong {font-weight:$font-weight-bold;}
    }
    figure img {max-height:80px;}
}


.spezialgebiete {padding:90px 0 120px;
    h2 {margin-bottom:30px;}
    &__ul {list-style:none;margin:0;padding:0;}
}
.blue-after {position:relative;
    > .container {position:relative;z-index:1;}    &::after {content:"";height:100px;width:100%;left:0;bottom:0;position:absolute;background:$primary;z-index:0;}}

.app {padding:50px 0;
    &__figure {position:absolute;top: -45%;max-width: 270px;left: 20px;}
    &__download img {max-width:160px;}
}

.home {
    .auswahl {padding-top:100px;
        &__suchformular {top:-105px;width:100%;left:0;}
    }
}

.carousel {
    &-inner {padding:100px 0;}
    &__text {padding:0 70px 0 100px;}
    &-control {position:absolute;bottom:40px;height:60px;width:60px;top:auto;left:48%;}
    &-control-next {left:55%;right:auto;}
}

.plzsuche {
    p {margin:30px auto;max-width:700px;}
    &__container {margin-bottom: -150px;z-index: 1;position: relative;}
}

.vorteile {
    &__ul {list-style:none;}
    &__li {padding:5px 0 5px 24px;position:relative;
        &::before {content:"";background:url("/assets/img/bullet.png") no-repeat 0 0 / auto 100%;color:$secondary;position:absolute;left:0;top:3px;width:30px;height:12px;}
    }
}
.shadow-bottom {box-shadow: 0px 20px 15px -20px $dark;position:relative;}
.padding-big {padding:220px 0 140px;}



#main {margin-top:110px;
 ul {list-style:none;padding:0;}
    ul:not(.list-unstyled) li {padding:5px 0 5px 24px;position:relative;
        &::before {content:"";background:url("/assets/img/bullet.png") no-repeat 0 0 / auto 100%;color:$secondary;position:absolute;left:0;top:11px;width:30px;height:12px;}
    }}
.shadow-hover {padding:20px;transition:ease all .4s;}
.footer {
    &__nav-ul {margin:0;padding:0;list-style:none;
        + ul {margin-top:50px;}
    }
    &__nav-li {padding:0 0 3px;}
    &__nav-titel {}
    &__social {}
    &__social-link {font-size:28px;display:inline-block;padding:0 6px;}
}
.swiper-pagination-bullet {width: 20px;height: 3px;display: inline-block;border-radius: 0;}
.swiper-pagination-bullet-active {background:$secondary;}

.form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after, .form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after {background:transparent;}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {padding-top:2rem;font-size:18px;}
@media (min-width:992px) {
    .boxen {
        &__box > div {box-sizing:border-box;padding-bottom:80px !important;position:relative;
            button {position:absolute;bottom:20px;left:50%;transform:translateX(-50%);white-space:nowrap;}}
    }
}
@include media-breakpoint-down(xl) {
    .header {
        &__schatten {top:105px;}
        .language {
            &__dropdown {left:auto;right:-20px;}
        }
    }
}

@include media-breakpoint-down(lg) {
    .carousel {
        &__text {padding-right: 3rem !important;padding-left: 3rem !important;}
        &-control-prev {left:40%;}
        &-inner {padding-top:60px;}
    }
    .navbar {
        &__offcanvas {box-sizing:border-box;padding:100px 20px 20px;}
        &-toggler {z-index:99999;position:relative;}
    }
    .header {
        &__topnav {right:auto;top:auto;bottom:20px;left:20px;}
        .language {
            &__dropdown {left:auto;right:-20px;}
        }
    }
    .hero {
        h1 {margin:60px 0 25px;}
    }
    .app {
        &__figure {top:auto;position:initial;}
    }
}
@include media-breakpoint-down(md) {
    .carousel {
        &__text {padding-right: 2rem !important;padding-left: 2rem !important;}
        &-inner {padding-top:30px;}
        &-control-prev {left:30%;}
        &__image {order:-1;margin:0 auto 20px;max-width:50%;display:block;}
    }
    .home {
        .auswahl {padding-top:20px;
            &__suchformular {top:auto;width:100%;left:auto;}
    }
}
     .header {
        &__schatten {top:105px;width:100vw;;}
    }
    .hero {
        &__bg::after {display:none !important;}
        &__teaser {top:auto;position:initial;color:$primary;transform:none;
            h1 {color:$primary !important;}
        }
    }
}

@include media-breakpoint-down(sm){
    #main {margin-top:80px;}

    .header {max-width:100vw;
        .container {margin:0;box-sizing:border-box;}
        &__logo {margin:15px 0;}
        &__schatten {top:20vw;}
    }
    .footer {
        &__nav-ul {margin-bottom:50px;text-align:center;}
    }
    .app {
        &__figure {position: relative;margin-top: -150px;left: 50%;}
    }
	.navbar-nav .dropdown-menu {max-width:96vw;padding-bottom:50px;height:60vh;}
}
@media (min-width: 420px) and (max-width:576px) {
    .header__schatten {
        top: 85px;
    }
}

// min-width
@include media-breakpoint-up(sm) {}
// max-width
@include media-breakpoint-down(sm) {}
